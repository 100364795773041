<template>
	<div class="new_expense_cature_modal_wrapper" :ref="customRef">
		<!--
      bg-variant="secondary"
      text-variant="white"
    -->
		<b-modal
			header-class="header-class-modal-doc-package"
			:title="FormMSG(200, 'Picture capture')"
			ref="modal"
			size="xl"
			v-model="isOpen"
			ok-variant="success"
			@ok="saveCapturedPics"
			@cancel="handleCloseModal"
			@hidden="handleCloseModal"
			:ok-title="FormMSG(201, 'Save')"
			:cancel-title="FormMSG(202, 'Cancel')"
			:class="clsObj"
		>
			<b-row class="expense_capture_modal_container">
				<b-col class="side_images_list" md="2">
					<CapturedImgList v-show="multipleImg" v-model="images" :key="refreshToken" @crop="handleCropImage" />
				</b-col>
				<b-col class="side_actions" :md="imagesListVisible ? '10' : '12'">
					<div v-if="!$isPwa()" class="centered_content" style="margin-bottom: 10px">
						<b-button-group v-show="!isCropOpen">
							<b-button :variant="`${!isCamMode ? '' : 'outline-'}primary`" @click="toggleImgMode">{{ FormMSG(1, 'Upload Image') }}</b-button>
							<b-button :variant="`${isCamMode ? '' : 'outline-'}primary`" @click="toggleImgMode">{{ FormMSG(2, 'From WebCam') }}</b-button>
						</b-button-group>
						<b-button v-show="isCropOpen" variant="outline-danger" @click="cancleCropImageAction">{{ FormMSG(3, 'Cancel Crop') }}</b-button>
					</div>

					<div v-if="isCropOpen" class="mod_img">
						<CropPicture
							v-bind="$props"
							ref="myCropPicture"
							:key="refreshToken"
							:props="(testTime = true)"
							:has-validation-button="!autoCropCapture"
							:base-url-img="currImgToCrop"
							:type="type"
							@change="handleCropImgChange"
							@cropped="handleAutoCrop"
						/>
					</div>

					<div v-else class="get_img">
						<CapturePicture
							v-if="isCamMode"
							:openned="isCamMode && value"
							has-no-file-uploader
							has-validation-button
							@change="handleCaptureChange"
						/>
						<GetDesktopImg v-else @change="handleDesktopImageSelected" />
					</div>
				</b-col>
			</b-row>

			<!-- <template #modal-footer>
        <div class="w-100">
          <p class="float-left">Modal Footer Content</p>
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="saveCapturedPics"
          >
            {{ FormMSG(124214, "Ok") }}

            @cancel="handleCloseModal"
            @hidden="handleCloseModal"
          </b-button>
        </div>
      </template> -->
		</b-modal>
	</div>
</template>

<script>
// const axios = require("axios").create();
import axios from 'axios';

import { isNil, isBase64 } from '@/shared/utils';
import { store } from '@/store';
import languageMessages from '@/mixins/languageMessages';

import CapturePicture from '@/components/CapturePicture';
import GetDesktopImg from '@/components/GetDesktopImg';
import CropPicture from '@/components/CropPicture';
import CapturedImgList from '@/components/ExpenseService/CapturedImgList';

// const authorizedParentsTypes = [
//   "temporary",
//   "expense_item",
//   "greenfilm",
//   "profile_picture",
// ];

export default {
	name: 'CaptureExpenseTicketComponent',
	components: {
		GetDesktopImg,
		CapturePicture,
		CropPicture,
		CapturedImgList
	},
	mixins: [languageMessages],
	props: {
		value: {
			type: Boolean,
			required: true
		},
		multipleImg: {
			type: Boolean,
			required: false,
			default: false
		},
		editData: {
			type: Object,
			required: false,
			default: null
		},
		autoCropCapture: {
			type: Boolean,
			required: false,
			default: false
		},
		customRef: {
			type: String,
			required: false,
			default: 'capturePicComponent'
		},
		camModeFirst: {
			type: Boolean,
			required: false,
			default: false
		},
		onlyOneCropMode: {
			type: Boolean,
			required: false,
			default: false
		},
		type: {
			type: String,
			required: false,
			default: 'default',
			validator: (v) => ['default', 'avatar'].includes(v)
		},

		// partents options
		parentId: {
			type: [Number, String],
			required: false,
			default: null
		},
		parentType: {
			type: String,
			required: false,
			default: null
			// validator: (v) => authorizedParentsTypes.includes(v),
		},
		parentSubType: {
			type: String,
			required: false,
			default: null
		}
	},
	data() {
		return {
			ticketPicture: { id: 0, name: '' },
			cropPict: false,
			isCropOpen: false,

			isCamMode: this.camModeFirst,

			images: [],
			savedImages: [],
			picturesToKeep: [], // image to keep for parent item
			picturesToKeepData: [], // images data to keep for parent item

			currImgToCrop: {},

			refreshToken: 0
		};
	},
	computed: {
		isOpen: {
			get: function () {
				return this.value;
			},
			set: function (val) {
				return val;
			}
		},

		// isCamMode: {
		//   get() {
		//     return this.camModeFirst
		//   },
		//   set(status) {
		//     return status
		//   }
		// },

		clsObj() {
			return {
				'has-multiple-images': this.multipleImg
			};
		},
		imagesListVisible() {
			return this.multipleImg && this.images.length;
		}
	},
	// watch: {
	//   savedImages: {
	//     handler(images) {
	//       this.$emit("change", images);
	//     },
	//     deep: true
	//   }
	// },
	created() {
		this.initImagesEdition();
	},
	methods: {
		initImagesEdition() {
			if (isNil(this.editData)) return;
			this.images = [...this.images];
			// this.savedImages = this.images
		},
		handleCloseModal() {
			// if (isNil(this.editData)) this.images = []
			// if (this.onlyOneCropMode) this.cancleCropImageAction()
			this.images = [];
			this.picturesToKeep = [];
			this.picturesToKeepData = [];
			this.savedImages = [];
			this.$emit('input', false);
		},

		endCrop(item) {
			// copy store image name into profile data
			if (store.state.savedImageName.length > 0) {
				this.ticketPicture.picture = store.state.savedImageName;
				// save profile for picture only!!!
				store.state.savedImageName = '';
			}
			this.cropPict = false;
		},
		endCropCancel(item) {
			if (store.state.savedImageName && store.state.savedImageName.length > 0) {
				// if new image was saved on the server, delete the image on the server !!!
				this.removeImage(store.state.savedImageName);
			}
			store.state.imageCropped = '';
			store.state.savedImageName = '';
			this.cropPict = false;
		},
		removeImage(imgName) {
			this.isLoading = true;
			let retStr = '';
			const fileName = imgName;
			let formData = new FormData(); // instantiate it
			formData.set('removeDocumentName', fileName);
			const config = {
				headers: {
					Authorization: `Bearer ${store.state.token}`,
					'content-type': 'multipart/form-data'
				}
			};
			const urlStr = process.env.VUE_APP_GQL + '/removeDoc';
			axios
				.post(urlStr, formData, config)
				.then((response) => {
					retStr = response.data;
					console.log('in remove doc retStr:', retStr);
					this.ticketPicture.picture = retStr;
					store.state.imageCropped = '';
					this.isLoading = false;
				})
				.catch((e) => console.log(e))
				.finally(() => (this.isLoading = false));
			return retStr;
		},
		b64toFile(b64Data, filename, contentType) {
			const sliceSize = 512;
			const byteCharacters = atob(b64Data);
			const byteArrays = [];
			for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
				const slice = byteCharacters.slice(offset, offset + sliceSize);
				const byteNumbers = new Array(slice.length);

				for (let i = 0; i < slice.length; i++) {
					byteNumbers[i] = slice.charCodeAt(i);
				}
				const byteArray = new Uint8Array(byteNumbers);
				byteArrays.push(byteArray);
			}
			const file = new File(byteArrays, filename, {
				type: contentType
			});
			return file;
		},

		async saveImage(imgData) {
			if (!isBase64(imgData)) return;

			const base64Data = imgData.split(';base64,');
			//    console.log("base64Data")
			//    console.log(base64Data)
			const ext = base64Data[0].replace('data:image/', '');

			const file = this.b64toFile(base64Data[1], `test.${ext}`, ext);
			let formData = new FormData();
			formData.set('uploadimage', file);
			if (!isNil(this.parentType)) formData.append('parentType', this.parentType);
			if (!isNil(this.parentSubType)) formData.append('parentSubType', this.parentSubType);
			if (!isNil(this.parentId)) formData.append('parentId', this.parentId);

			try {
				const savedImg = await this.$axios.$post('upload', formData, {
					headers: {
						Authorization: `Bearer ${store.state.token}`,
						'content-type': 'multipart/form-data'
					}
				});
				this.picturesToKeep.push(savedImg);
				this.picturesToKeepData.push(imgData);

				this.savedImages = this.onlyOneCropMode ? [savedImg] : [...this.savedImages, savedImg];
				this.isLoading = false;
			} catch (e) {
				console.error({ e });
			}
		},

		/**
		 * @param {Array} images
		 * @param {Number} id
		 */
		async loopImages(images) {
			// await images.forEach((img) => {
			//   if (!isBase64(img)) {
			//     this.picturesToKeep.push(img);
			//     return;
			//   }
			//   this.saveImage(img);
			// });

			for (const img of images) {
				if (!isBase64(img)) {
					this.picturesToKeep.push(img);
				} else {
					await this.saveImage(img);
				}
			}
		},

		async saveCapturedPics() {
			this.isLoading = true;
			const ed = this.editData;
			if (!isNil(ed) && !isNil(ed.images)) this.savedImages = ed.images;
			await this.loopImages(this.images);

			// console.log({ 'this.picturesToKeep': this.picturesToKeep })
			this.isLoading = false;

			this.$emit('change', this.picturesToKeep, this.picturesToKeepData);
			this.images = [];
			this.picturesToKeep = [];
			this.savedImages = [];

			this.isOpen = false;
		},

		handleCapture(image) {
			this.images.push(image);
		},

		handleDesktopImageSelected(img) {
			this.images.push(img);
		},

		toggleImgMode() {
			this.isCamMode = !this.isCamMode;
		},

		/**
		 * @param {Object} elem
		 */
		handleCropImage(elem) {
			if (isNil(elem)) return;
			const { img, index } = elem;
			this.cropPict = true;
			this.currImgToCrop = { img, index };
			store.state.imageToCrop = img;
			this.isCropOpen = true;
		},

		handleAutoCrop(img) {
			if (!this.autoCropCapture) return;
			this.images[0] = img;
		},

		cancleCropImageAction() {
			this.cropPict = false;
			this.currImgToCrop = {};
			store.state.imageToCrop = '';
			this.isCropOpen = false;
			this.setNewRefreshToken();
		},

		setNewRefreshToken() {
			this.refreshToken = this.refreshToken + 1;
		},

		/**
		 * @param {Object} crop
		 */
		handleCropImgChange(crop) {
			if (isNil(crop)) return;
			const { img, index } = crop;
			this.images[index] = img;
			this.setNewRefreshToken();
			this.isCropOpen = false;
		},

		handleCaptureChange(caputure) {
			if (isNil(caputure)) return;
			if (this.autoCropCapture) this.handleCropImage(caputure);
			this.images.push(caputure.img);
			this.setNewRefreshToken();
		}
	}
};
</script>
