<template>
	<transition name="slide">
		<b-form>
			<fieldset class="scheduler-border">
				<legend class="scheduler-border">{{ FormMSG(16, 'Form') }}</legend>
				<b-form-group :label="FormMSG(1, 'Name')" label-for="name" :label-cols-sm="3">
					<b-form-input v-model="location.setName" type="text" :placeholder="FormMSG(2, 'Enter name...')" autocomplete="off" />
				</b-form-group>
				<b-form-group :label="FormMSG(5, 'Description')" label-for="description" :label-cols-sm="3">
					<b-form-textarea
						v-model="location.description"
						type="textarea"
						rows="3"
						:placeholder="FormMSG(6, 'Enter description...')"
						autocomplete="off"
					/>
				</b-form-group>
				<b-form-group :label="FormMSG(3, 'Used')" label-for="used" :label-cols-sm="3">
					<b-form-checkbox size="lg" v-model="location.selected" />
				</b-form-group>
				<b-form-group :label="this.FormMSG(4, 'Type')" label-for="optionsForLocationType" :label-cols-sm="3">
					<b-form-select v-model="location.type" :options="optionsForLocationType" />
				</b-form-group>
				<b-form-group :label="FormMSG(7, 'Note')" label-for="note" :label-cols-sm="3">
					<b-form-textarea v-model="location.note" type="textarea" rows="3" :placeholder="FormMSG(8, 'Enter note...')" autocomplete="off" />
				</b-form-group>
				<b-form-group :label="getLocationAddressLabel" :label-cols-sm="3">
					<main-address-location
						:params="mainLocationAddress"
						:address-id="location[mainLocationAddress.name]"
						@change="handleDynamicAddressChange"
						@delete="handleDynamicAddressDelete"
					/><!--  @address-maplink-change="handleAddressMapLinkChange" -->
				</b-form-group>
			</fieldset>
			<b-row>
				<b-col cols="12">
					<b-card no-body>
						<template #header>
							<div class="d-flex flex-row">
								<div class="mr-2 text-success task-icon-card-header">
									<i class="fa fa-image" aria-hidden="true"></i>
								</div>
								<div class="text-muted task-label-card-header">
									<h6 class="mb-0 font-weight-bold">{{ FormMSG(13, 'Images') }}</h6>
								</div>
							</div>
						</template>
						<b-card-body>
							<div v-if="location.images === undefined || location.images.length === 0" ref="empty-image" class="text-center empty-item-text">
								{{ FormMSG(14, 'No image selected') }}
							</div>
							<location-images v-else :img-data="images" @image:removed="imageRemoved" />
						</b-card-body>
						<template #footer>
							<b-row>
								<b-col :sm="`${$screen.width >= 992 ? 9 : 6}`">&nbsp;</b-col>
								<b-col :sm="`${$screen.width >= 992 ? 3 : 6}`">
									<b-form-group class="my-0">
										<b-button
											type="button"
											variant="outline-primary"
											size="md"
											class="py-2 px-4 tags-assigned"
											@click="togglePictureModal"
											block
										>
											<span>{{ FormMSG(15, 'Add') }}</span>
										</b-button>
									</b-form-group>
								</b-col>
							</b-row>
						</template>
					</b-card>
				</b-col>
				<capture
					v-model="isCaptureModalOpen"
					multiple-img
					dynamic-crop-size
					:edit-data="location"
					:parent-id="location.id"
					parent-type="location"
					parent-sub-type="images"
					@change="handleImgsTaked"
				/>
			</b-row>
			<b-row>
				<b-col cols="12">
					<b-card no-body header-tag="header" footer-tag="footer">
						<template #header>
							<div class="d-flex flex-row">
								<div class="mr-2 text-success task-icon-card-header">
									<i class="fa fa-files-o" aria-hidden="true"></i>
								</div>
								<div class="text-muted task-label-card-header">
									<h6 class="mb-0 font-weight-bold">{{ FormMSG(17, 'Documents') }}</h6>
								</div>
							</div>
						</template>
						<b-card-body>
							<b-row>
								<b-col cols="12">
									<green-film-docs
										ref="cardDocumentList"
										:GFId="location.id"
										:show-input-file="false"
										GFType="location"
										GFSubType="documents"
										@el:updated="greenFilmDocsMounted"
									/>
									<div v-if="elDocMounted === true && greenFilmDocLength === 0" class="text-center empty-item-text">
										{{ FormMSG(18, 'No document selected') }}
									</div>
								</b-col>
							</b-row>
						</b-card-body>
						<template #footer>
							<b-row>
								<b-col :sm="`${$screen.width >= 992 ? 9 : 6}`">&nbsp;</b-col>
								<b-col :sm="`${$screen.width >= 992 ? 3 : 6}`">
									<b-form-group class="my-0">
										<b-button
											type="button"
											variant="outline-primary"
											size="md"
											class="py-2 px-4 tags-assigned"
											@click="openDocumentModal"
											block
										>
											<span>{{ FormMSG(19, 'Add') }}</span>
										</b-button>
									</b-form-group>
								</b-col>
							</b-row>
						</template>
					</b-card>
				</b-col>
				<client-location-document-modal
					v-model="isOpenDocumentModal"
					:location-id="location.id"
					@client-location-document:hidden="onHideDocumentModal"
				/>
			</b-row>
		</b-form>
	</transition>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import { store } from '@/store';
import MainAddressLocation from '@/components/LocationService/MainAddressLocation';
import { mapActions } from 'vuex';
import LocationImages from '@/components/Task/ImgShowroomTable';
import Capture from '@/components/ExpenseService/Capture';
import ClientLocationDocumentModal from '@/components/ClientLocation/Document/Modal';
import GreenFilmDocs from '@/components/greenfilm/greenfilmdocs';

export default {
	name: 'ClientLocationForm',
	mixins: [languageMessages],
	props: {
		item: {
			type: Object,
			required: true,
			default: null
		}
	},
	components: {
		MainAddressLocation,
		LocationImages,
		Capture,
		ClientLocationDocumentModal,
		GreenFilmDocs
	},
	computed: {
		images() {
			const tmp = [];
			this.location.images.forEach((img) => {
				tmp.push({
					filename: img
				});
			});

			return tmp;
		},
		optionsForLocationType() {
			let options = [
				{
					value: 0,
					text: this.FormMSG(9, 'Choose a type...')
				}
			];

			let typeOptions = [];
			if (this.isFilm()) {
				typeOptions = this.FormMenu(1026);
			} else if (this.isPme()) {
				typeOptions = this.FormMenu(1322);
			} else if (this.isAgency()) {
				typeOptions = this.FormMenu(1323);
			}

			if (typeOptions.length >= 1 && typeOptions[0].text.trim() !== 'not found') {
				typeOptions.forEach((type) => {
					options.push(type);
				});
			}

			return options;
		},
		getLocationAddressLabel() {
			return this.FormMSG(10, 'Address');
		},
		mainLocationAddress() {
			return {
				name: 'setAddressId',
				title: this.getTitle,
				address: 'setAddress'
			};
		},
		getTitle() {
			return this.isPme() ? this.FormMSG(11, 'Office Details') : this.FormMSG(12, 'Location information');
		}
	},
	data() {
		return {
			erreur: {},
			warning: '',
			location: {},
			isCaptureModalOpen: false,
			isOpenDocumentModal: false,
			elDocMounted: false,
			greenFilmDocLength: 0
		};
	},
	mounted() {
		this.$nextTick(() => {
			this.location = this.item;
			this.reloadDocumentList(this.location.id);
		});
	},
	methods: {
		isFilm() {
			return store.isFilm();
		},
		isPme() {
			return store.isPme();
		},
		isAgency() {
			return store.isAgency();
		},
		...mapActions({
			updateLocation: 'locations/updateLocation',
			getProjectLocationList: 'locations/getProjectLocationList',
			destroyCurrentLocation: 'locations/destroyCurrentLocation'
		}),
		async handleDynamicAddressChange(item) {
			this.$emit('client-location-form-address:updated', {
				id: parseInt(item.id, 10),
				address: item.address
			});
		},
		async handleDynamicAddressDelete(key) {
			this.$emit('client-location-form-address:updated', {
				id: 0,
				address: {
					box: '',
					city: '',
					country: '',
					countryCode: '',
					countryKey: 0,
					id: '0',
					lat: 0,
					lng: 0,
					number: '',
					state: '',
					street: '',
					zip: ''
				}
			});
		},
		imageRemoved(payload) {
			this.location.images.splice(
				this.location.images.findIndex((image) => (image.lastIndexOf('.') === -1 ? image : image.substring(0, image.lastIndexOf('.')) === payload)),
				1
			);
		},
		togglePictureModal() {
			this.isCaptureModalOpen = true;
		},
		async handleImgsTaked(imgs) {
			for (let i = 0; i < imgs.length; i++) {
				const imgSplitted = imgs[i].split('.')[0];
				this.location.images.push(imgSplitted);
			}
		},
		onHideDocumentModal(payload) {
			this.isOpenDocumentModal = false;
			// do reload table documents in card
			this.reloadDocumentList(payload);
		},
		reloadDocumentList(locationId) {
			this.$refs.cardDocumentList.reloadData(locationId);
		},
		greenFilmDocsMounted(payload) {
			this.elDocMounted = true;
			this.greenFilmDocLength = payload;
		},
		openDocumentModal() {
			this.isOpenDocumentModal = true;
		}
	}
};
</script>
