<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		ref="modal"
		v-model="isOpen"
		size="lg"
		:title="modalTitle"
		ok-variant="success"
		:ok-title="okTitle"
		@ok="onSubmit"
		@hidden="onCancel"
		ok-only
	>
		<b-container fluid>
			<b-row>
				<b-col cols="12">
					<green-film-docs :GFId="locationId" GFType="location" GFSubType="documents" />
				</b-col>
			</b-row>
		</b-container>
	</b-modal>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import Loading from 'vue-loading-overlay';
import GreenFilmDocs from '@/components/greenfilm/greenfilmdocs';

export default {
	name: 'ClientLocationDocumentModal',
	mixins: [languageMessages],
	components: {
		Loading,
		GreenFilmDocs
	},
	props: {
		value: {
			type: Boolean,
			required: true,
			default: false
		},
		locationId: {
			type: [String, Number],
			required: true,
			default: '0'
		}
	},
	computed: {
		isOpen: {
			get() {
				return this.value;
			},
			set(val) {
				return val;
			}
		},
		modalTitle() {
			return this.FormMSG(1, 'Add documents');
		},
		okTitle() {
			return this.FormMSG(2, 'Ok');
		}
	},
	methods: {
		onSubmit() {
			this.$emit('client-location-document:hidden', this.locationId);
		},
		onCancel() {
			this.$emit('client-location-document:hidden', this.locationId);
		}
	}
};
</script>
