var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _c(
          "div",
          [
            _vm.$screen.width < 992
              ? _c("card-list-builder", {
                  attrs: { items: _vm.items, fields: _vm.locationMobileFields },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "actions",
                        fn: function (data) {
                          return [
                            _c(
                              "b-button",
                              {
                                staticClass: "mr-1 btn bg-transparent border-0",
                                attrs: { variant: "primary", size: "sm" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editLocation(data.item)
                                  },
                                },
                              },
                              [
                                _c(_vm.getLucideIcon(_vm.ICONS.EDIT.name), {
                                  tag: "component",
                                  attrs: {
                                    color: _vm.ICONS.EDIT.color,
                                    size: 20,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-button",
                              {
                                staticClass: "btn bg-transparent border-0",
                                attrs: { variant: "danger", size: "sm" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeLocation(data.item.id)
                                  },
                                },
                              },
                              [
                                _c(_vm.getLucideIcon(_vm.ICONS.TRASH.name), {
                                  tag: "component",
                                  attrs: {
                                    color: _vm.ICONS.TRASH.color,
                                    size: 20,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1211173477
                  ),
                })
              : _vm._e(),
            _vm.$screen.width >= 992
              ? _c("b-table", {
                  ref: "clientLocationTable",
                  attrs: {
                    hover: _vm.hover,
                    responsive: "sm",
                    items: _vm.items,
                    fields: _vm.locationFields,
                    "current-page": _vm.currentPage,
                    "per-page": _vm.perPage,
                    "head-variant": _vm.hv,
                    bordered: "",
                    small: "",
                    "empty-text": _vm.FormMSG(1, "No location found"),
                    "show-empty": "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "cell(edit)",
                        fn: function (data) {
                          return [
                            _c(
                              "b-button",
                              {
                                staticClass: "btn bg-transparent border-0",
                                attrs: { variant: "primary", size: "sm" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editLocation(data.item)
                                  },
                                },
                              },
                              [
                                _c(_vm.getLucideIcon(_vm.ICONS.EDIT.name), {
                                  tag: "component",
                                  attrs: {
                                    color: _vm.ICONS.EDIT.color,
                                    size: 20,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(delete)",
                        fn: function (data) {
                          return [
                            _c(
                              "b-button",
                              {
                                staticClass: "btn bg-transparent border-0",
                                attrs: { variant: "danger", size: "sm" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeLocation(data.item.id)
                                  },
                                },
                              },
                              [
                                _c(_vm.getLucideIcon(_vm.ICONS.TRASH.name), {
                                  tag: "component",
                                  attrs: {
                                    color: _vm.ICONS.TRASH.color,
                                    size: 20,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1460653762
                  ),
                })
              : _vm._e(),
            _c("client-location-modal", {
              attrs: { location: _vm.location },
              on: {
                "client-location-modal:cancel": _vm.onLocationCanceled,
                "client-location-modal:updated": _vm.onLocationUpdated,
              },
              model: {
                value: _vm.isOpenLocationModal,
                callback: function ($$v) {
                  _vm.isOpenLocationModal = $$v
                },
                expression: "isOpenLocationModal",
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }