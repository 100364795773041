var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c(
        "b-modal",
        {
          ref: "client-location-modal",
          attrs: {
            "header-class": "header-class-modal-doc-package",
            size: "lg",
            "ok-title": _vm.FormMSG(2, "Save"),
            "cancel-title": _vm.FormMSG(3, "Cancel"),
            "cancel-variant": "light",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.save, cancel: _vm.onCancel, hidden: _vm.onCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function () {
                return [
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.modalTitle) },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.isOpen,
            callback: function ($$v) {
              _vm.isOpen = $$v
            },
            expression: "isOpen",
          },
        },
        [
          _c(
            "div",
            [
              _c("client-location-form", {
                attrs: { item: _vm.location },
                on: {
                  "client-location-form-address:updated": _vm.locationUpdated,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }