var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    { attrs: { name: "slide" } },
    [
      _c(
        "b-form",
        [
          _c(
            "fieldset",
            { staticClass: "scheduler-border" },
            [
              _c("legend", { staticClass: "scheduler-border" }, [
                _vm._v(_vm._s(_vm.FormMSG(16, "Form"))),
              ]),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.FormMSG(1, "Name"),
                    "label-for": "name",
                    "label-cols-sm": 3,
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      type: "text",
                      placeholder: _vm.FormMSG(2, "Enter name..."),
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.location.setName,
                      callback: function ($$v) {
                        _vm.$set(_vm.location, "setName", $$v)
                      },
                      expression: "location.setName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.FormMSG(5, "Description"),
                    "label-for": "description",
                    "label-cols-sm": 3,
                  },
                },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      type: "textarea",
                      rows: "3",
                      placeholder: _vm.FormMSG(6, "Enter description..."),
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.location.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.location, "description", $$v)
                      },
                      expression: "location.description",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.FormMSG(3, "Used"),
                    "label-for": "used",
                    "label-cols-sm": 3,
                  },
                },
                [
                  _c("b-form-checkbox", {
                    attrs: { size: "lg" },
                    model: {
                      value: _vm.location.selected,
                      callback: function ($$v) {
                        _vm.$set(_vm.location, "selected", $$v)
                      },
                      expression: "location.selected",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: this.FormMSG(4, "Type"),
                    "label-for": "optionsForLocationType",
                    "label-cols-sm": 3,
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: { options: _vm.optionsForLocationType },
                    model: {
                      value: _vm.location.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.location, "type", $$v)
                      },
                      expression: "location.type",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.FormMSG(7, "Note"),
                    "label-for": "note",
                    "label-cols-sm": 3,
                  },
                },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      type: "textarea",
                      rows: "3",
                      placeholder: _vm.FormMSG(8, "Enter note..."),
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.location.note,
                      callback: function ($$v) {
                        _vm.$set(_vm.location, "note", $$v)
                      },
                      expression: "location.note",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.getLocationAddressLabel,
                    "label-cols-sm": 3,
                  },
                },
                [
                  _c("main-address-location", {
                    attrs: {
                      params: _vm.mainLocationAddress,
                      "address-id": _vm.location[_vm.mainLocationAddress.name],
                    },
                    on: {
                      change: _vm.handleDynamicAddressChange,
                      delete: _vm.handleDynamicAddressDelete,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-card",
                    {
                      attrs: { "no-body": "" },
                      scopedSlots: _vm._u([
                        {
                          key: "header",
                          fn: function () {
                            return [
                              _c("div", { staticClass: "d-flex flex-row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "mr-2 text-success task-icon-card-header",
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-image",
                                      attrs: { "aria-hidden": "true" },
                                    }),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-muted task-label-card-header",
                                  },
                                  [
                                    _c(
                                      "h6",
                                      { staticClass: "mb-0 font-weight-bold" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.FormMSG(13, "Images"))
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "footer",
                          fn: function () {
                            return [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: `${
                                          _vm.$screen.width >= 992 ? 9 : 6
                                        }`,
                                      },
                                    },
                                    [_vm._v(" ")]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: `${
                                          _vm.$screen.width >= 992 ? 3 : 6
                                        }`,
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        { staticClass: "my-0" },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "py-2 px-4 tags-assigned",
                                              attrs: {
                                                type: "button",
                                                variant: "outline-primary",
                                                size: "md",
                                                block: "",
                                              },
                                              on: {
                                                click: _vm.togglePictureModal,
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.FormMSG(15, "Add"))
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c(
                        "b-card-body",
                        [
                          _vm.location.images === undefined ||
                          _vm.location.images.length === 0
                            ? _c(
                                "div",
                                {
                                  ref: "empty-image",
                                  staticClass: "text-center empty-item-text",
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(14, "No image selected")
                                      ) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]
                              )
                            : _c("location-images", {
                                attrs: { "img-data": _vm.images },
                                on: { "image:removed": _vm.imageRemoved },
                              }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("capture", {
                attrs: {
                  "multiple-img": "",
                  "dynamic-crop-size": "",
                  "edit-data": _vm.location,
                  "parent-id": _vm.location.id,
                  "parent-type": "location",
                  "parent-sub-type": "images",
                },
                on: { change: _vm.handleImgsTaked },
                model: {
                  value: _vm.isCaptureModalOpen,
                  callback: function ($$v) {
                    _vm.isCaptureModalOpen = $$v
                  },
                  expression: "isCaptureModalOpen",
                },
              }),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-card",
                    {
                      attrs: {
                        "no-body": "",
                        "header-tag": "header",
                        "footer-tag": "footer",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "header",
                          fn: function () {
                            return [
                              _c("div", { staticClass: "d-flex flex-row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "mr-2 text-success task-icon-card-header",
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-files-o",
                                      attrs: { "aria-hidden": "true" },
                                    }),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-muted task-label-card-header",
                                  },
                                  [
                                    _c(
                                      "h6",
                                      { staticClass: "mb-0 font-weight-bold" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.FormMSG(17, "Documents"))
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "footer",
                          fn: function () {
                            return [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: `${
                                          _vm.$screen.width >= 992 ? 9 : 6
                                        }`,
                                      },
                                    },
                                    [_vm._v(" ")]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: `${
                                          _vm.$screen.width >= 992 ? 3 : 6
                                        }`,
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        { staticClass: "my-0" },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "py-2 px-4 tags-assigned",
                                              attrs: {
                                                type: "button",
                                                variant: "outline-primary",
                                                size: "md",
                                                block: "",
                                              },
                                              on: {
                                                click: _vm.openDocumentModal,
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.FormMSG(19, "Add"))
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c(
                        "b-card-body",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("green-film-docs", {
                                    ref: "cardDocumentList",
                                    attrs: {
                                      GFId: _vm.location.id,
                                      "show-input-file": false,
                                      GFType: "location",
                                      GFSubType: "documents",
                                    },
                                    on: {
                                      "el:updated": _vm.greenFilmDocsMounted,
                                    },
                                  }),
                                  _vm.elDocMounted === true &&
                                  _vm.greenFilmDocLength === 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-center empty-item-text",
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  18,
                                                  "No document selected"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("client-location-document-modal", {
                attrs: { "location-id": _vm.location.id },
                on: {
                  "client-location-document:hidden": _vm.onHideDocumentModal,
                },
                model: {
                  value: _vm.isOpenDocumentModal,
                  callback: function ($$v) {
                    _vm.isOpenDocumentModal = $$v
                  },
                  expression: "isOpenDocumentModal",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }