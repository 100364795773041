import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import { isNil } from '~utils';

export const getDocuments = async (id, parentType, parentSubType) => {
	const {
		data: { GetGreenFilmDocs }
	} = await apollo.query({
		query: gql`
			query ($id: ID!, $parentType: String!, $parentSubType: String!) {
				GetGreenFilmDocs(GreenFilmId: $id, ParentType: $parentType, ParentSubType: $parentSubType) {
					id
					publisher {
						name
						firstName
					}
					fileName
					xid
					publishedOn
				}
			}
		`,
		variables: {
			id: parseInt(id, 10),
			parentType,
			parentSubType
		},
		fetchPolicy: 'no-cache'
	});

	return GetGreenFilmDocs;
};

export const getAttachStatus = async (xid) => {
	const {
		data: { GetImageChildAttachStatus }
	} = await apollo.query({
		query: gql`
			query ($xid: String!) {
				GetImageChildAttachStatus(Xid: $xid) {
					attachToOrder
					attachToOffer
					attachToInvoice
				}
			}
		`,
		variables: {
			xid
		},
		fetchPolicy: 'no-cache'
	});

	return GetImageChildAttachStatus;
};

export const updateImageChild = async (xid, labelNumber, value) => {
	const mutation = gql`
		mutation UPDATE_IMAGE_CHILD($xid: String!, $labelNumber: ID!, $value: Boolean!) {
			UpdateImageChild(Xid: $xid, LabelNumber: $labelNumber, Value: $value)
		}
	`;
	const {
		data: { UpdateImageChild }
	} = await apollo.mutate({
		mutation,
		variables: {
			xid,
			labelNumber,
			value
		},
		fetchPolicy: 'no-cache'
	});

	return UpdateImageChild;
};

export const getImageChild = async (xid) => {
	try {
		const query = gql`
			query ($xid: String!) {
				GetImageChildDetails(Xid: $xid) {
					id
					createdAt
					updatedAt
					xid
					xidThumb
					ext
					parentType
					parentSubType
					parentID
					publisherID
					userID
					projectID
					originalFileName
					licenceId
					attachToOffer
					attachToOrder
					attachToInvoice
				}
			}
		`;

		const {
			data: { GetImageChildDetails }
		} = await apollo.query({
			query,
			variables: {
				xid
			}
		});

		return GetImageChildDetails;
	} catch (e) {
		console.log(e);
	}
};
