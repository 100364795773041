var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: _vm.customRef, staticClass: "new_expense_cature_modal_wrapper" },
    [
      _c(
        "b-modal",
        {
          ref: "modal",
          class: _vm.clsObj,
          attrs: {
            "header-class": "header-class-modal-doc-package",
            title: _vm.FormMSG(200, "Picture capture"),
            size: "xl",
            "ok-variant": "success",
            "ok-title": _vm.FormMSG(201, "Save"),
            "cancel-title": _vm.FormMSG(202, "Cancel"),
          },
          on: {
            ok: _vm.saveCapturedPics,
            cancel: _vm.handleCloseModal,
            hidden: _vm.handleCloseModal,
          },
          model: {
            value: _vm.isOpen,
            callback: function ($$v) {
              _vm.isOpen = $$v
            },
            expression: "isOpen",
          },
        },
        [
          _c(
            "b-row",
            { staticClass: "expense_capture_modal_container" },
            [
              _c(
                "b-col",
                { staticClass: "side_images_list", attrs: { md: "2" } },
                [
                  _c("CapturedImgList", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.multipleImg,
                        expression: "multipleImg",
                      },
                    ],
                    key: _vm.refreshToken,
                    on: { crop: _vm.handleCropImage },
                    model: {
                      value: _vm.images,
                      callback: function ($$v) {
                        _vm.images = $$v
                      },
                      expression: "images",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "side_actions",
                  attrs: { md: _vm.imagesListVisible ? "10" : "12" },
                },
                [
                  !_vm.$isPwa()
                    ? _c(
                        "div",
                        {
                          staticClass: "centered_content",
                          staticStyle: { "margin-bottom": "10px" },
                        },
                        [
                          _c(
                            "b-button-group",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.isCropOpen,
                                  expression: "!isCropOpen",
                                },
                              ],
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: `${
                                      !_vm.isCamMode ? "" : "outline-"
                                    }primary`,
                                  },
                                  on: { click: _vm.toggleImgMode },
                                },
                                [_vm._v(_vm._s(_vm.FormMSG(1, "Upload Image")))]
                              ),
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: `${
                                      _vm.isCamMode ? "" : "outline-"
                                    }primary`,
                                  },
                                  on: { click: _vm.toggleImgMode },
                                },
                                [_vm._v(_vm._s(_vm.FormMSG(2, "From WebCam")))]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isCropOpen,
                                  expression: "isCropOpen",
                                },
                              ],
                              attrs: { variant: "outline-danger" },
                              on: { click: _vm.cancleCropImageAction },
                            },
                            [_vm._v(_vm._s(_vm.FormMSG(3, "Cancel Crop")))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isCropOpen
                    ? _c(
                        "div",
                        { staticClass: "mod_img" },
                        [
                          _c(
                            "CropPicture",
                            _vm._b(
                              {
                                key: _vm.refreshToken,
                                ref: "myCropPicture",
                                attrs: {
                                  props: (_vm.testTime = true),
                                  "has-validation-button": !_vm.autoCropCapture,
                                  "base-url-img": _vm.currImgToCrop,
                                  type: _vm.type,
                                },
                                on: {
                                  change: _vm.handleCropImgChange,
                                  cropped: _vm.handleAutoCrop,
                                },
                              },
                              "CropPicture",
                              _vm.$props,
                              false
                            )
                          ),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "get_img" },
                        [
                          _vm.isCamMode
                            ? _c("CapturePicture", {
                                attrs: {
                                  openned: _vm.isCamMode && _vm.value,
                                  "has-no-file-uploader": "",
                                  "has-validation-button": "",
                                },
                                on: { change: _vm.handleCaptureChange },
                              })
                            : _c("GetDesktopImg", {
                                on: { change: _vm.handleDesktopImageSelected },
                              }),
                        ],
                        1
                      ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }