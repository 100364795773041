export const isFileSupported = (file, supportedMimeTypes, unsupportedMimeTypes = null) => {
	const fileType = file.type;
	//exemple supportedMimeTypes = ['.png,.jpeg', '.jpg', ...]
	const isSupported = supportedMimeTypes.some((type) => {
		const supportedTypes = type.split(',');
		return supportedTypes.some((supportedType) => file.name.toLowerCase().endsWith(supportedType.trim()));
	});
	if (unsupportedMimeTypes) {
		const isUnsupported = unsupportedMimeTypes.some((type) => fileType.startsWith(type));
		return isSupported && !isUnsupported;
	}

	return isSupported;
};
